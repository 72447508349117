@font-face {
  font-family: 'Friz Quadrata Std';
  src: local('Friz Quadrata Std'), url('./fonts/friz_quadrata_std_medium_.otf') format('opentype')
}

@font-face {
  font-family: 'Aller';
  src: local('Aller'), url('./fonts/Aller_Std_Rg.ttf') format('truetype');
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(238,234,223,1);
}

html {
  font-family: 'Aller', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-font {
  font-family: 'Friz Quadrata Std';
	overflow: visible;
	font-style: normal;
	font-weight: bold;
	font-size: 68px;
	color: rgba(92,142,41,1);
	letter-spacing: 0.2px;
	text-transform: capitalize;
}

a.title-font:hover {
  color: rgb(65, 102, 28);
}

.subtitle-font {
    font-family: Aller;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    color: rgba(0,0,0,1);
    text-transform: capitalize;
}

/* Override default Bootstrap CSS */
.btn-primary{
  background-color: #145424;
  border-color: #145424;
}

.btn-primary:disabled {
  background-color: #206932;
  border-color: #206932;
}

.btn-primary:hover {
  background-color: #145424;
  border-color: #145424;
}

.btn-primary:disabled{
  background-color: #226633;
  border-color: #226633;
}

.btn-primary:active {
  background-color: #145424;
  border-color: #145424;
}

.btn-primary:focus {
  background-color: #145424;
  border-color: #145424;
}

.bg-primary {
  background-color: #145424 !important;
}

.card{
  background-color: rgba(238,234,223,1);
  border-width: 5px;
  border-radius: 10px;
  border-color: #145424;
}

.card-header {
  border-width: 4px;
  border-color: #145424;
}

.card-footer {
  border-width: 4px;
  border-color: #145424;
}

/* Other stuff */
.thick-border {
  border-width: 5px;
  border-color: #145424;
  border-radius: 10px;
  border-style: solid;
}

.chat-scroll {
  height: 77%;
  overflow-y: auto;
}

.moderator-chat-scroll {
  height: 87%;
  overflow-y: auto;
}

/*modal styling borrowed from https://github.com/timbrownls20/Demo/blob/master/React/bootstrap-modal/src/css/modal.css*/
.modal{
  display: block;
  visibility: hidden;

}

.modal-show
{
  visibility:visible;
  background-color: rgba(169, 169, 169, 0.8);
  transition: opacity 0.2s linear; 
} 

.modal-content 
{
  opacity: 0; 
  background-color: rgba(238,234,223,1);
  border-width: 2px;
  border-radius: 10px;
  border-color: #243953;
}

.modal-show .modal-content 
{
  opacity: 1; 
  transition: 0.2s linear; 
} 

.modal-header {
  border-width: 2px;
  border-color: #243953;
}

.modal-footer {
  border-width: 2px;
  border-color: #243953;
}

/* 
   Bootstrap style overrides for Thirsty Earth game
------------------------------------------------------- */

.bg-tan {
  background: #eeeadf;
}

.bg-navy {
  background: #243953;
}
.border-navy {
  border: 3px solid #243953;
}
.bg-med-navy {
  background: #7d868f;
}
.bg-lt-navy {
  background: #d7d6cf;
}

.bg-green {
  background: #5d9351;
}

.bg-dirt {
  background: #8e4d26;
  border: 2px solid #5d9351;
}
.bg-wet-dirt {
  background: #5f7f91;
  border: 2px solid #5d9351;
}
.bg-dirt-bank {
  background: #8e4d26;
  border: 2px solid #243953;
  border-radius: 12px;
}
.bg-wet-dirt-bank {
  background: #5f7f91;
  border: 2px solid #243953;
  border-radius: 12px;
}

.header-font {
  letter-spacing: .25px;
  opacity: .75;
}

.bg-dirt img,
.bg-wet-dirt img {
  max-width: 70px;
}

.img-thumb {
  max-width: 28%;
  padding: 4%;
}

.img-tiny {
  max-width: 20%;
  padding: 2%;
}

.img-icon {
  width: 20px;
  margin-top: -5px;
  margin-left: 5px;
  Opacity: .75;
}

.water-summary img {
  background: #5f7f91;
  width: 17%;
}
.crop-summary img {
  background: #8e4d26;
  width: 17%;
}

.btn-submit {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-submit:hover {
  color: #fff;
  background-color: #145424;
  border-color: #145424;
}

.btn-submit:disabled {
  color: #fff;
  background-color: #145424;
  border-color: #145424;
}

.btn-navy {
  color: #fff;
  background-color: #243953;
  border-color: #243953;
}
.btn-navy:disabled {
  color: #fff;
  background-color: #2b4768;
  border-color: #2b4768;
}
.btn-navy:hover {
  color: #fff;
  background-color: #0c2340;
  border-color: #0c2340;
}

.nav-pills.nav-navy .nav-link {
  color: #243953;
}
.nav-pills.nav-navy .nav-link.active {
  color: #fff;
  background-color: #243953;
}

.is-warning {
  border-color: #fe8b2d;
}

.alert-warning {
  color: #572c01;
  background-color: #ffcda9;
  border-color: #fcaa5e;
}

.btn-warning {
  background-color: #f18e32;
  border-color: #f18e32;
}

.btn-warning:hover {
  background-color: #e47a17;
  border-color: #e47a17;
}

.btn-warning:disabled{
  background-color: #fba351;
  border-color: #fba351;
}

.btn-warning:active {
  background-color: #e47a17;
  border-color: #e47a17;
}

.btn-warning:focus {
  background-color: #e47a17;
  border-color: #e47a17;
}

.bg-warning {
  background-color: #f18e32 !important;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(83, 83, 83, 0.383);
  z-index: 2;
  cursor: pointer;
}

.overlay-text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  text-align: center;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}